import React from 'react'
import Link from 'gatsby-link'
import { MDBRow, MDBCol } from 'mdbreact'
import { MDBAnimation } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"

class ColumnOverlayFocus extends React.Component {
  render() {
    return (
      <>
        <MDBRow>
          <MDBCol lg="12">
            <MDBAnimation reveal type="fadeInUp">
              <div className="features-absolute rounded bg-white">
                <MDBRow>
                  <MDBCol lg="12" md="12" className="my-4">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="d-flex p-4">
                          <div className="flex-1">
                            <p
                              className="font-alt font-w-200 text-center title-xs-small title-large mb-xl-2 mx-3"
                              dangerouslySetInnerHTML={{ __html: this.props.focus.title, }}
                            />
                          </div>
                        </div>
                      </MDBCol>

                      <MDBCol md="12">
                        <div className="d-flex">
                          <div className="flex-1">
                            <h2
                              className="font-w-700 text-center font-alt mb-4 mx-3 pb-4 letter-spacing-1 title-xs-large"
                              dangerouslySetInnerHTML={{ __html: this.props.focus.subtitle, }}
                            />
                          </div>
                        </div>
                      </MDBCol>

                      {this.props.focus.items.map((item, index) => {
                        return (
                          <MDBCol lg="3" md="6" className="text-center mt-3" key={index}>
                            <div className="d-flex p-0 pt-5 pt-lg-0">
                              <div className="flex-1">
                                {item.link ? 
                                <Link to={item.link} className="text-medium-small">
                                  <GatsbyImage 
                                    image={item.image.childImageSharp.gatsbyImageData} 
                                    alt={item.alttext}
                                  />
                                </Link>
                                :
                                <GatsbyImage 
                                  image={item.image.childImageSharp.gatsbyImageData} 
                                  alt={item.alttext}
                                />
                                }
                                <h3 className="font-alt font-w-700 title-extra-small title-md-small py-4 overlay-title">{item.title}</h3>
                              </div>
                            </div>
                          </MDBCol>
                        )
                      })}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBAnimation>
          </MDBCol>
        </MDBRow>
      </>
    )
  }
}

export default ColumnOverlayFocus
